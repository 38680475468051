import { ChangeDetectorRef, Component } from '@angular/core';
import { EtlIngestionService } from '../../services/etl-ingestion/etl-ingestion.service';
import { AppService } from '../../app.service';
import { catchError, finalize, of, tap } from 'rxjs';
import { FileIngestionConfigService } from '../../services/file-ingestion-config/file-ingestion-config.service';

@Component({
  selector: 'file-ingestion-config',
  templateUrl: './file-ingestion-config.component.html',
  styleUrl: './file-ingestion-config.component.css'
})
export class FileIngestionConfigComponent {
  isSideBarVisible: boolean = false;
  isDeleteModalVisible: boolean = false;
  toDeleteItem:any;
  editedId: string = '';
  creditorId:string = '';
  fileIngestionConfigs!: any[]; 
  creditors: any[] | undefined;
  selectedCreditor: any | undefined;
  isHideAddNew: boolean = false;

  overwriteAmountDueOnly: boolean = false;
  missingInvoicesStatusId: number | undefined;

public constructor(
  private etlIngestionService: EtlIngestionService, 
  public appService: AppService, 
  private fileIngestionConfigService: FileIngestionConfigService,
  private cdr: ChangeDetectorRef) { }

ngOnInit() {
   this.etlIngestionService.getCreditors().subscribe((res:any) => {
    if(!!res && res.Creditors && Array.isArray(res.Creditors) && res.Creditors.length > 0){
      this.creditors = res.Creditors;
    }   
   });
}

onChangeCreditor(event: any){
  this.fileIngestionConfigs = [];
  this.creditorId = this.selectedCreditor.CreditorId;
}

search(){
  if(!!this.creditorId){
    this.appService.isShowPageSpinner = true;
    this.fileIngestionConfigs = [];
    this.fileIngestionConfigService.get(this.creditorId)
      .pipe(
        tap((res: any ) => {
          if(!!res){
            this.fileIngestionConfigs = [res];
            this.isHideAddNew = true;
          }  else{
            this.isHideAddNew = false;
          }
        }),
        catchError(error => {          
          this.appService.openCommonModal('Error','There seems to be an error getting the File Ingestion Config.');
          return of(null); 
        }),
        finalize(() => {   
          this.appService.isShowPageSpinner = false;
        })
      ).subscribe();
   }
}

onOpenSideBar(id: string = '', overwriteAmountDueOnly: boolean = false, missingInvoicesStatusId: number | undefined = undefined){
 if(!!this.selectedCreditor){
  this.editedId = id;
  this.overwriteAmountDueOnly = overwriteAmountDueOnly;
  this.missingInvoicesStatusId = missingInvoicesStatusId;
  this.isSideBarVisible = true;
 }else{
  this.appService.commonModalHeader = 'Validation';
  this.appService.commonModalMsg = 'Please select a creditor first.';
  this.appService.isCommonModalVisible = true;
 }
}

onOpenDeleteModal(item: any){
  this.toDeleteItem = item;
  this.isDeleteModalVisible = true;
}


onConfirmDelete(){
  if(!!this.toDeleteItem){
    this.appService.isShowPageSpinner = true;

    this.fileIngestionConfigService.delete(this.toDeleteItem).pipe(
      tap(() => {
        this.appService.commonModalHeader = 'Success';
        this.appService.commonModalMsg = 'File Ingestion Config has been successfully deleted.';
        this.fileIngestionConfigs = this.fileIngestionConfigs.filter((i:any) => i.FileIngestionConfigId !== this.toDeleteItem.FileIngestionConfigId);
        this.isDeleteModalVisible = false;
      }),
      catchError(error => {
        this.appService.commonModalHeader = 'Error';
        this.appService.commonModalMsg = 'There seems to be an error deleting the File Ingestion Config.';
        return of(null); // Handle the error and return an observable
      }),
      finalize(() => {
        this.appService.isCommonModalVisible = true;
        this.appService.isShowPageSpinner = false;
      })
    )
    .subscribe();
  }
}

onSave(){
  this.appService.isShowPageSpinner = true;

  if(!!this.missingInvoicesStatusId){
    if(!this.editedId && !!this.selectedCreditor){
      this.fileIngestionConfigService.post(this.selectedCreditor.CreditorId, this.overwriteAmountDueOnly, this.missingInvoicesStatusId)
      .pipe(
        tap((res: any ) => {
            this.appService.commonModalHeader = 'Success';
            this.appService.commonModalMsg = 'File Ingestion Config has been successfully created.';
            this.isSideBarVisible = false;
            this.search();
        }),
        catchError(error => {
          this.appService.commonModalHeader = 'Error';
          this.appService.commonModalMsg = 'There seems to be an error saving the File Ingestion Config.';
          return of(null); 
        }),
        finalize(() => {
          this.appService.isCommonModalVisible = true;
          this.appService.isShowPageSpinner = false;
        })
      ).subscribe();
    }else{
      this.fileIngestionConfigService.put(this.editedId, this.overwriteAmountDueOnly, this.missingInvoicesStatusId)
      .pipe(
        tap(() => {
          this.appService.commonModalHeader = 'Success';
          this.appService.commonModalMsg = 'File Ingestion Config has been successfully updated.';
          this.isSideBarVisible = false;

          let newFileIngestionConfig = this.fileIngestionConfigs[0];
          newFileIngestionConfig.overwriteAmountDueOnly = this.overwriteAmountDueOnly;
          newFileIngestionConfig.missingInvoicesStatusId = this.missingInvoicesStatusId;

          this.fileIngestionConfigs = [newFileIngestionConfig];
          this.cdr.detectChanges();

          //do search since above code ain't working
          setTimeout(() => {
            this.search();
          },500)
        }),
        catchError(error => {
          this.appService.commonModalHeader = 'Error';
          this.appService.commonModalMsg = 'There seems to be an error saving the File Ingestion Config.';
          this.appService.isShowPageSpinner = false;
          return of(null); 
        }),
        finalize(() => {
          this.appService.isCommonModalVisible = true;
        })
      )
      .subscribe();
    } 
  }else{
    this.appService.commonModalHeader = 'Validation';
    this.appService.commonModalMsg = 'Please input Missing Invoices Status Id';
    this.appService.isCommonModalVisible = true;
  }
  
}

}

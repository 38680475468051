import { ChangeDetectorRef, Component } from '@angular/core';
import { EtlIngestionService } from '../../services/etl-ingestion/etl-ingestion.service';
import { AppService } from '../../app.service';
import { catchError, finalize, of, tap } from 'rxjs';
import { NotificationContactsService } from '../../services/notification-contacts/notification-contacts.service';

@Component({
  selector: 'app-notification-contacts',
  templateUrl: './notification-contacts.component.html',
  styleUrl: './notification-contacts.component.css'
})
export class NotificationContactsComponent {
    isSideBarVisible: boolean = false;
    isDeleteModalVisible: boolean = false;
    toDeleteItem:any;
    editedId: number = 0;
    creditorId:string = '';
    notificationContacts!: any[]; 
    creditors: any[] | undefined;
    selectedCreditor: any | undefined;

    firstName: string = '';
    lastName: string = '';
    email: string = '';

  public constructor(
    private etlIngestionService: EtlIngestionService, 
    private appService: AppService, 
    private notificationContactService: NotificationContactsService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit() {
     this.etlIngestionService.getCreditors().subscribe((res:any) => {
      if(!!res && res.Creditors && Array.isArray(res.Creditors) && res.Creditors.length > 0){
        this.creditors = res.Creditors;
      }   
     });
  }

  onChangeCreditor(event: any){
    this.notificationContacts = [];
    this.creditorId = this.selectedCreditor.CreditorId;
  }

  search(){
    if(!!this.creditorId){
      this.appService.isShowPageSpinner = true;
      this.notificationContactService.get(this.creditorId)
        .pipe(
          tap((res: any ) => {
            if(!!res && Array.isArray(res) && res.length > 0){
              this.notificationContacts = res;
            }  
          }),
          catchError(error => {          
            this.appService.openCommonModal('Error','There seems to be an error getting the Notification Contacts.');
            return of(null); 
          }),
          finalize(() => {   
            this.appService.isShowPageSpinner = false;
          })
        ).subscribe();
     }
  }

  onOpenSideBar(id: number = 0, firstName: string = '', lastName: string = '', email:string = ''){
    if(!!this.selectedCreditor){
      this.editedId = id;
      this.firstName = firstName;
      this.lastName = lastName;
      this.email = email;
      this.isSideBarVisible = true;
    }else{
      this.appService.commonModalHeader = 'Validation';
      this.appService.commonModalMsg = 'Please select a creditor first.';
      this.appService.isCommonModalVisible = true;
    }
  }

  onOpenDeleteModal(item: any){
    this.toDeleteItem = item;
    this.isDeleteModalVisible = true;
  }


  onConfirmDelete(){
    if(!!this.toDeleteItem){
      this.appService.isShowPageSpinner = true;

      this.notificationContactService.delete(this.toDeleteItem).pipe(
        tap(() => {
          this.appService.commonModalHeader = 'Success';
          this.appService.commonModalMsg = 'Notification Contact has been successfully deleted.';
          this.notificationContacts = this.notificationContacts.filter((i:any) => i.NotificationContactId !== this.toDeleteItem.NotificationContactId);
          this.isDeleteModalVisible = false;
        }),
        catchError(error => {
          this.appService.commonModalHeader = 'Error';
          this.appService.commonModalMsg = 'There seems to be an error deleting the Notification Contact.';
          return of(null); // Handle the error and return an observable
        }),
        finalize(() => {
          this.appService.isCommonModalVisible = true;
          this.appService.isShowPageSpinner = false;
        })
      )
      .subscribe();
    }
  }

  onSave(){     
      this.appService.isShowPageSpinner = true;
      if(!this.editedId && !!this.selectedCreditor){
        this.notificationContactService.post(+this.selectedCreditor.CreditorId, this.firstName, this.lastName, this.email)
        .pipe(
          tap((res: any ) => {            
            setTimeout(() => {  
              this.appService.commonModalHeader = 'Success';
              this.appService.commonModalMsg = 'Notification Contact has been successfully created.';
              this.isSideBarVisible = false;
              this.appService.isCommonModalVisible = true;  
              this.search();
              },2000)

          }),
          catchError(error => {
            this.appService.commonModalHeader = 'Error';
            this.appService.commonModalMsg = 'There seems to be an error saving the Notification Contact.';
            this.appService.isCommonModalVisible = true;
            this.appService.isShowPageSpinner = false;
            return of(null); 
          }),
          finalize(() => {
            
           
          })
        ).subscribe();
      }else{
        this.notificationContactService.put(this.editedId, +this.selectedCreditor.CreditorId, this.firstName, this.lastName, this.email)
        .pipe(
          tap(() => {
            this.appService.commonModalHeader = 'Success';
            this.appService.commonModalMsg = 'Notification Contact has been successfully updated.';
            this.isSideBarVisible = false;
  
            const newNotificationContacts = this.notificationContacts.map((item: any) => {
              if (item.NotificationContactId == this.editedId) {
                item.FirstName = this.firstName; 
                item.LastName = this.lastName;
                item.Email = this.email;
              }
              return item;
            });
            this.notificationContacts = newNotificationContacts;
            this.cdr.detectChanges();
          }),
          catchError(error => {
            this.appService.commonModalHeader = 'Error';
            this.appService.commonModalMsg = 'There seems to be an error saving the Notification Contact.';
            return of(null); 
          }),
          finalize(() => {
            this.appService.isCommonModalVisible = true;
            this.appService.isShowPageSpinner = false;
          })
        )
        .subscribe();
      }       
  }

}
